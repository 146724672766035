import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from './lang/index'
// svg icon
import '@/assets/icons'
import '@/utils/rem'


Vue.use(ElementUI,{ 
  size: 'samll',
  i18n: (key,value) => i18n.t(key,value)
})

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
