<template>
  <div class="container">
    <homeCom :active-value="active"></homeCom>
    <div class="scroll_area">
      <div>
        <router-view @menuChange="menuChange"></router-view>
      </div>
      <footerCom @menuChange="menuChange"></footerCom>
    </div>
    <el-backtop target=".scroll_area"></el-backtop>
  </div>
</template>

<script>
// import { screenSize } from '@/utils/util'
import homeCom from '@/components/head/index.vue'
import footerCom from '@/components/footer/index.vue'

export default {
  name: 'App',
  components: {
    homeCom,
    footerCom,
  },
  data() {
    return {
      active: '/home',
    }
  },
  mounted () {
    // screenSize(this.$refs.editor)
  },
  methods: {
    menuChange(value) {
      console.log('change',value)
      this.active = value
    }
  },
}
</script>

<style>
.container .scroll_area {
  width: 100%;
  height: calc(100vh - 62px);
  overflow: auto;
  color: #2D2D2D;
}

</style>
  