export default {
  navbar: {
    home: '首页',
    product: '产品及解决方案',
    case: '行业案例',
    about: '关于我们',
    contact: '联系我们',
    service_ability: '合作咨询',
    join_us: '招贤纳士',
  },
  home: {
    head_title: "工业认知AI赋能可持续发展",
    head_slogan: '保护环境，人人有责。让地球远离污染，让绿色走进家园。一花一草皆生命，一枝一叶总关情。爱护小草，从你做起',
    request: '免费获取解决方案',
    ai_title: '工业AI先锋',
    ai_content: '技术自主可控，预测精度高、结果可解释。团队具有国际能源部署经验，结合工业建模仿真语言MODELICA，针对工业场景融合AI技术定制解决方案，使决策可靠、风险可控、模型物理意义可解释，突破消除样本多样性和耦合性的技术难点。',
    expert_driven: '专家知识驱动',
    expert_content: '工业领域权威专家指导AI学习模型更可靠、更安全',
    break_trough: '突破经验束缚',
    break_content: '智能决策优化方案弥补行业专家经验局限',
    visual: '可视就是生产力',
    visual_content: '工业机理模型、AI、数字孪生融合可视更可操作',
    exercise: '自我学习演练',
    exercise_content: '工业场景机理模型在线学习、迭代优化',
    tabs_factory: '制造工厂',
    oil_field: '智慧油田',
    real_estate: '商业地产',
    partner: '合作伙伴',
    welcome: '欢迎新伙伴与我们合作',
  },
  product: {
    head_title: '智慧工厂解决方案',
    head_content: '邦定智慧针对智慧工厂领域，将机理模型与认知AI有机结合，为工业客户提供能源管理、节能优化控制、设备预测性维护与故障诊断全套解决方案，助力企业数智化绿色转型，打造零碳工厂。',
    head_deploy: '部署一套冷站控制系统，制冷机房年综合能效可以达到良好运行区间 (EER>5.0)，达到高效制冷机房系统。',
    energy_efficiency: '节能降费',
    energy_content: '节能量提升20%-30%，并且节省投入及运维成本超过50%。',
    low_carbon:'低碳运行',
    low_content: '以中等规模制药工厂为例，1年可降低1000吨碳排放。',
    increase_production: '提升产量',
    increase_content: '工厂综合产量提升10%-20%，减少能耗15%-25%。',
    uation_room:'数智运维',
    uation_content: '设备故障实时监测，显著减少设备故障率，避免冗余工作量。',
    why_energy:'为什么要做工业节能？',
    why_energy_info:'工业节能对我国实现2030年前碳排放达峰目标意义非凡，贡献超过 70% 。',
    why_key: '工业节能的关键是？',
    why_key_info: '暖通空调、空压系统能耗占比高达 30%~50% ，且空调系统相互作用影响，难以实时优化。',
    why_realize: '如何有效实现？',
    why_realize_info: '邦定5.0认知AI节能方案有效提升 45% 节能率。',
    one_energy: '一步节能45%',
    level_one: '成本大、风险高、难验证，影响生产',
    level_two: '依赖专家，无法实时寻优',
    level_three: '黑箱模型，误差大',
    level_four: '国内主流算法',
    device_cloud: '设备上云  全面物联',
    device_cloud_info: '数据实时采集  实时分析  实时监测  一套系统完成故障诊断、节能优化两大目标',
    fan: '风机',
    pump: '水泵',
    motor: '电机',
    airComp: '空压机',
    dryer: '冷机',
    cool: '冷却塔',
    vrv: '中央空调设备',
    recycler: '余热回收机',
    system_highlights: '系统亮点',
    real_time_monitoring: '实时监控',
    simulation: '模拟仿真',
    fault_tracking: '故障追踪',
    data_visualization: '数据可视化',
  },
  case: {
    AI_empowerment: '工业认知·AI赋能',
    sustainable_development: '可持续发展',
    factory: '制造工厂',
    project_context: '项目背景',
    preenable: '赋能前',
    after_empowerment: '赋能后',

    moke_biology:'世界500强知名外资药厂',
    moke_project_info: '该药厂位于南通经济技术开发区内，一期工程总占地面16038.62㎡，总建筑面积24248.99㎡。空间系统运行出现大流量、小温差的特点，运行内容效率需要改善。',
    moke_preenable: '系统仪表需要更新为高精度仪器仪表，水泵、冷却塔变频控制柜完善；在满足末端需求的前提下，急需对制冷站空调自控系统进行AI优化升级;智能化管理，部署优化冷站BDM控制软件。',
    description1: '装配高精度仪器仪表,完善水泵,冷却塔变频控制柜',
    description2: '部署优化冷站BDM优化控制软件',
    moke_after_text1_title: '能源站 ',
    moke_after_text1_cont: '节能量提升20%~25%',
    moke_after_text2_title: '满足制药厂GMP管理，',
    moke_after_text2_cont: '运维效率提升约2倍；',
    moke_after_text3_cont: '运维成本降低约50%',
    moke_after_text4_cont: '有效防范制药厂等能源站存在的潜在生产风险。',

    commercial_real_estate: '商业地产',
    intelligent_island: '张江人工智能岛',
    land_project_info: '张江人工智能岛占地总面积达 10万㎡，目标打造成中国 AI 新地标，力争 2020 年底建成中国最具影响力和代表性的人工智能产业聚集区与体现人工智能最新技术、最高水平的应用示范区。邦定智慧应用应邀参加园区室内环境智能化建设方案。',
    land_preenable: '园区对管理运营层提出了更高的运营要求，需以安全、舒适、高效，便捷、智慧为主题建设；实际运营中多建筑、多系统、多设备集中管理困难，没有配置专门的设施管理系统；员工工资逐年上涨，人力成本不断攀升，同事耗能成本难以降低，整体成本不升反降。',
    land_after_text1_title: '提高运维管理效率：',
    land_after_text1_cont: '建筑设备管理自动化、无纸化、移动化，故障远程维护、诊断、在线编程；',
    land_after_text2_title: '设备异常智能监测：',
    land_after_text2_cont: '设备状态实时监测告警、溯源故障信息分析并智能推送至对应人员维护；',
    land_after_text3_title: 'AI优化降耗：',
    land_after_text3_cont: '以AI算法深度挖掘设备数据并分析设备最佳运行策略，实现设备管理层节能降耗；',
    land_after_text4_title: '自动托管运营：',
    land_after_text4_cont: '以与能源供给数据结合，自动训练数据模型，完成整个系统的智能托管。',

    chengdu: '成都万象城',
    chengdu_project_info: '成都万象城作为华润集团旗下大型商业综合体，总建筑面积达18.8万㎡。项目改造工程需保留原有传感器和BA群控，新增部分硬件、云平台、AI算法等。',
    chengdu_preenable: '使用定频泵塔，无法及时有效地根据不同天气情况和用冷需求实现整体智能调控；传感器问题频出，数据漏报误报，急需综合诊断管控。',
    chengdu_after_text1_cont1: '中央空调',
    chengdu_after_text1_cont2: '节能15%-25%，',
    chengdu_after_text1_cont3: '实现智慧运维；',
    chengdu_after_text2_cont: '极大降低一线人员劳动强度，提高工作效率；提升顾客购物舒适度，有效实现品牌价值',

    chart_ai_cont: '实现工业认知AI赋能持续发展',
    chart_promise_head: '对客户承诺',
    chart_promise_info1: '24×7 在线技术支持，48小时承诺保修',
    chart_promise_info2: '保修期内持续更新软件 提供可选的新功能和系统升级',
    chart_accretion: '为工厂增值',
    chart_energy_rate: '节能率: 预期节能 20%-30%',
    chart_payback_time: '投资回收期：1-2 年',
    chart_sustainability: '可持续性: 减少碳排放量',
    chart_running_attention: '运行维护: 提高运行效率',
    chart_now_relation: '现在就联系我们>',
  },
  about: {
    who_are_we: '我们是谁？',
    head_info: '邦定智慧是具有国际成功经验和自主可控技术的工业AI企业，公司融合在海内外工业智能领域的成熟方案与技术储备，积极响应国家号召，致力于为工业客户提供核心工业AI引擎，与客户一同打造国产工业AI+的应用生态，实现国产化代替，优化工业生产全流程。',
    head_cont: '公司提供深入定制工业大数据分析与AI计算、机理建模仿真优化等人工智能解决方案与平台，为客户管理能效，优化生产，节能减排，提高综合收益。',
    mission: '使命',
    mission_cont: '打造国产工业AI+应用生态优化生产全流程',
    energize: '赋能',
    energize_cont: '工业认知AI赋能可持续发展',
    vision: '愿景',
    vision_cont: '以智慧科技实现安邦定国创业初心',
    we_story: '我们的故事',
    we_month_01: "2022年9月",
    we_info_01: "与500强医药行业德国默克合作，实现制冷机房数智化升级改造。",
    we_month_02: "2022年5月",
    we_info_02: '获默克亚太区供应商创新大赛最佳创新项目奖，并受邀入驻默克上海创新基地，成为首批入驻企业。',
    we_month_03: "2021年7月",
    we_info_03: "与浙江大学合作赋能工控领域头部企业浙大中控，顺利完成相关项目。",
    we_month_04: "2021年4月",
    we_info_04: "获两大石油巨头中国石化、中国石油准入证，成功进入石油产业链并开启相关信息化智能化创新项目。",
    we_month_05: "2021年3月",
    we_info_05: "与华润数科合作赋能大型商业综合体，实现楼宇节能改造。",
    we_month_06: "2020年",
    we_info_06: "与张江创新基地合作完成张江人工智能岛，多次被相关媒体报道并受赞赏。",
    we_month_07: "2019年",
    we_info_07: "上海邦定智慧科技有限公司成立。",

    honor: '荣誉',
    honor_text01: '2023年荣获上海市浦东新区明珠计划菁英人才项目支持',
    honor_text02: "2023年上海市浦东新区明珠计划菁英人才项目颁奖",
    honor_text03: "2023年第四届中央企业熠星创新创意大赛参赛优秀成果",
    honor_text04: "2023年36氪《看见新力量》专访邦定智慧创始人曹俊：自主可控的认知AI，使石油开采长出“智慧大脑”",
    honor_text05: "2023年阿里巴巴诸神之战智能制造赛道奖杯",
    honor_text06: "2023年阿里巴巴诸神之战全球挑战赛创新应用奖",
    honor_text07: "2023年阿里巴巴诸神之战全球挑战赛最佳人气奖",
    honor_text08: "2022年华润高层参观考察邦定智慧项目人工智能岛",
    honor_text09: "2022年受邀参加AI与节能的技术讲座(德国商会)",
    honor_text10: "2022年默克亚太区供应商创新大赛最佳创新项目奖",
    honor_text11: "2022年默克上海创新基地首批入驻企业",
    honor_text12: "2022年张江携手默克创新 邦定赋能低碳节能",
    honor_text13: "2021年杜邦国际亚太区董事邹飚博士参观邦定智慧",
    honor_text14: "2021年第1视角报道邦定智慧创业历程",
    honor_text15: "2020年第二十二届中国工博会参展企业",
    honor_text16: "2020年中宣部参观并赞赏邦定AI大数据算法为油田、工业等领域带来的成果",
    honor_text17: "2020年中央广电总台环球英语中心《浦东30年》系列专访",
    honor_text18: "2020年莘庄工业区《守归国之志》高层次创新创业人才分享会",
    honor_text19: "2019年中央广播电视总台财经节目中心《创业英雄汇》报道",
    honor_text20: "2018年创青春上海青年创新创业大赛智能制造单元优胜奖",

    core_team: '核心团队',
    team_info: '团队具有丰富的工业大数据分析及AI工程技术实践，以及国际石油石化、医药、航空航天、自动控制、建筑与工厂等领域卓越运营的解决方案经验，服务国内国际多家世界500强',
    people_name: '曹俊',
    education: '德国亚琛工业大学 土木工程与计算机 博士',
    post: '同济大学绿色建筑与新能源中心特聘研究员（兼职）',
    people_info: '入选国家人社部中国留学人员回国创业启动支持计划、上海市浦江人才、上海市青年创业英才、闵行区海外高端引进人才、春申金字塔杰出人才 13年工业AI与能源优化的技术研发、项目管理与运营经验，曾服务联合国国际能源总署（IEA）、德国联邦经济和能源部（BMWi）等重点项目研发',
    forge_ahead: '砥砺前行',

  },
  contact: {
    talent: {
      info: '留下具体信息，我们会为您提供合适的解决方案。 您也可以通过电话、邮件等方式联系我们。',
      serve: '全国服务热线',
      email: '邮箱',
      address: '地址',
      address_info: '上海市浦东新区纳贤路800号B座4楼',
      form_name: '请填写姓名',
      form_company: '请填写公司名称',
      form_countryOrArea: '请填写国家/地区',
      form_phone: '请填写可以联系到您的电话号码',
      form_email: '请填写可以联系到您的邮箱地址',
      form_real_email: '请输入正确的邮箱地址',
      form_describe: '请简单描述您遇到了什么情况，或是您需要哪方面的支持 ',
      form_desc: '请输入需求简述',
      form_submit: '提交',
    },
    cooperation: {
      head: '邦定智慧 智创未来',
      join: '期待你的加入',
      deliver: '投递邮箱',

      job_description: '岗位职责',
      qualification: '任职资格',

      post_title_01: '仿真建模工程师',
      post_duty1_01: '1. 基于Modelica语言开发能源、机械系统等的仿真模型，如生产线、能源转换设备等仿真模型;',
      post_duty1_02: '2. 参与Modelica建模引擎的模块设计与开发;',
      post_duty1_03: '3. 仿真模型库的开发、维护。',
      post_office1_01: "1. 统招本科及以上学历，机械、能源动力、电气工程等专业；0~3年工作经验。",
      post_office1_02: "2. 掌握Modelica语言，至少具有Dymola/Mworks/OpenModelice/Simulink中一款仿真软件的的使用经验。",
      post_office1_03: "3. 加分项：具有能源、建筑领域相关项目工作经验或者仿真软件使用经验者优先",
      post_office1_04: "4. 具有良好沟通能力及协调沟通能力。",

      post_title_02: "暖通节能工程师",
      post_duty2_01: "1. 参与暖通节能优化与运维管理软件的研发工作;",
      post_duty2_02: "2. 参与暖通系统节能优化的软件算法的研发（参与并指导IT工程师设计算法）;",
      post_duty2_03: "3. 参与暖通系统设备故障诊断与预测的软件算法研发（参与并指导IT工程师设计算法）;",
      post_duty2_04: "4. 参与楼宇暖通节能与优化项目的方案设计;",
      post_office2_01: "1. 全日制普通高等院校本科及以上学历，建筑环境与能源应用工程（暖通）及相关专业;",
      post_office2_02: "2. 3-5年或以上暖通设计相关工作经验者，有暖通节能工程项目从业经验;",
      post_office2_03: "3. 熟悉暖通空调或给排水施工流程、技术及其其它专业知识;",
      post_office2_04: "4. 有大型暖通设计、节能改造设计项目的独立全程工作经验者优先;",
      post_office2_05: "5. 持工程师资格证书者优先;",
      post_office2_06: "加分项：",
      post_office2_07: "熟悉暖通设备仿真建模技术者优先（如：EnergyPlus、Matlab、Simulink等）;",
      post_office2_08: "具有编程开发能力者优先（如：Python或Java等）;",
      post_office2_09: "具有良好的沟通和协调能力;",
      post_office2_10: "熟练应用Office、AutoCAD等软件进行暖通系统图纸设计。",

      post_title_03: "算法工程师",
      post_duty3_01: "1. 基于机器学习/深度学习等技术，结合智慧建筑、智慧油田等工业领域的专家知识，在建筑运维、石油开采等多个行业，深入研发可适用于客户的运筹优化算法和机器学习模型;",
      post_duty3_02: "2. 研发适用于上述业务场景的自动化预测相关系统，包括数据模型构建、自动化机器学习（特征工程、模型优化等）、模型部署等，包括模块的实际开发以及客户自身系统的对接;",
      post_duty3_03: "3. 公司安排的其它相关工作。",
      post_office3_01: "1. 本科及以上学历，统计学、计算机科学等相关专业背景，3年以上算法应用经验;",
      post_office3_02: "2. 至少精通运筹优化、数据挖掘、机器学习、深度学习等中的一项技术;",
      post_office3_03: "3. 熟练使用SAS、R、python等相关统计分析语言，快速的模型迭代与代码实现能力，对Python熟悉者优先;",
      post_office3_04: "4. 良好的沟通和协调能力。",

      post_title_04: "销售经理（能源方向）",
      post_duty4_01: "1. 在外资制药工厂或大型工厂进行AI产品和节能技术的推广，负责市场拓展与推广，进行行业需求分析，制定市场开发策略；",
      post_duty4_02: "2. 渠道/合作方的开发及日常维护，促进新项目的获取；",
      post_duty4_03: "3. 为项目提供匹配的完整解决方案，包含节能量计算、核算、经济性分析和报价方案（公司会提供相关培训和提供支持工作）；",
      post_duty4_04: "4. 负责项目的商务对接，协调项目进程，主导商务沟通与谈判，确保项目签约落地；",
      post_duty4_05: "5. 负责大客户关系维护，协调推进项目签约后的回款工作；",
      post_duty4_06: "6. 负责本区域上下游生态合作圈的搭建和整合；",
      post_duty4_07: "7. 对已签约项目的复盘沉淀（业务收益和成本资源投入等），并提出优化改进建议；",
      post_office4_01: "1. 本科及以上学历，暖通、热能、自动化、机械类等工科相关专业；如英语口语交流为加分项；",
      post_office4_02: "2. 具有B端客户行业的销售经验；",
      post_office4_03: "3. 敏锐的市场分析判断能力和优秀的商务谈判能力，较强的公关能力及跟踪服务能力，有较强的抗压及独挡一面的能力，有优质客户资源的优先；",
      post_office4_04: "4. 以结果为导向，能自我管理和自我驱动；",
      post_office4_05: "5. 有踏实吃苦的精神，和团队同甘共苦，能适应较高频率出差；",
      post_office4_06: "6. 良好的职业操守和行业口碑（经得起第三方独立的背景调查）；",
    }
  },
  footer: {
    phoneTitle: '商务合作洽谈请致电',
    workTime: '工作时间',
    service: '如有紧急需求可以随时联系客服',
    address:'地址',
    addressUrl: '上海市浦东新区纳贤路800号B座4楼',
    phone: '电话',
    email: '邮箱',
    copyright: '版权所有 ©2020 上海邦定智慧科技有限公司',
    Wechat: '扫码添加客服微信',
  }
}