import Vue from "vue";
import VueRouter from "vue-router";
import Main from "@/views/index.vue"

// 路由重复跳转解决方法↓↓↓↓↓
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(err => { })
};
// ↑↑↑↑↑
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      component: Main,
      redirect: '/home',
    },
    {
      path: '/home',
      meta: {
        title: '首页',
      },
      redirect: '/home/index',
      component: Main,
      children: [
        {
          path: 'index',
          name: 'Home',
          hidden: true,
          component: () => import('@/views/home/index.vue'),
          meta: { title: '首页' }
        },
      ]
    },
    {
      path: '/product',
      meta: {
        title: '产品及解决方案',
      },
      component: Main,
      redirect: '/product/index',
      children: [
        {
          path: 'index',
          name: 'Product',
          hidden: true,
          component: () => import('@/views/product/index.vue'),
          meta: { title: '产品及解决方案' }
        },
      ]
    },
    {
      path: '/case',
      meta: {
        title: '行业案例',
      },
      component: Main,
      redirect: '/case/index',
      children: [
        {
          path: 'index',
          name: 'Case',
          hidden: true,
          component: () => import('@/views/case/index.vue'),
          meta: { title: '行业案例' }
        },
      ]
    },
    {
      path: '/about',
      meta: {
        title: '关于我们',
      },
      component: Main,
      redirect: '/case/index',
      children: [
        {
          path: 'index',
          name: 'About',
          hidden: true,
          component: () => import('@/views/about/index.vue'),
          meta: { title: '关于我们' }
        },
      ]
    },
    {
      path: '/contact',
      meta: {
        title: '联系我们',
      },
      component: Main,
      children: [
        {
          path: '/contact/talentRecruitment',
          meta: {
            title: '合作咨询',
          },
          component: () => import('@/views/contact/talentRecruitment.vue')
        },
        {
          path: '/contact/cooperationConsultation',
          meta: {
            title: '招贤纳士',
          },
          component: () => import('@/views/contact/cooperationConsultation.vue')
        },
      ],
    },
  ]
})

router.beforeEach((to, form, next) => {
  // console.log('to：',to,'      form：',form)
  document.title = to.meta.title

  next()
})


export default router