<template>
  <div class="head_box">
    <el-row type="flex" justify="space-between">
      <el-col :span="4">
        <div class="head_img"></div>
      </el-col>
      <el-col :span="16">
        <el-menu
          :default-active="active"
          class="el-menu-demo"
          background-color="#ffffff"
          text-color="#000000"
          active-text-color="#4eca65"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="/home">{{ $t("navbar.home") }}</el-menu-item>
          <el-menu-item index="/product">{{
            $t("navbar.product")
          }}</el-menu-item>
          <el-menu-item index="/case">{{ $t("navbar.case") }}</el-menu-item>
          <el-menu-item index="/about/index">{{ $t("navbar.about") }}</el-menu-item>
          <!-- <el-menu-item index="/contact">{{ $t("navbar.contact") }}</el-menu-item> -->
          <el-submenu index="/contact">
            <template slot="title">{{ $t("navbar.contact") }}</template>
            <el-menu-item index="/contact/talentRecruitment">{{ $t('navbar.service_ability') }}</el-menu-item>
            <el-menu-item index="/contact/cooperationConsultation">{{ $t('navbar.join_us') }}</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>
      <el-col :span="2" style="text-align: center">
        <el-dropdown trigger="click" @command="changeLang">
          <span class="head_lang">
            {{ languaga }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh" :disabled="'zh' === $i18n.locale"
              >中文</el-dropdown-item
            >
            <el-dropdown-item command="en" :disabled="'en' === $i18n.locale"
              >EN</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Cookie from "js-cookie";
export default {
  name: "homeCom",
  props: {
    activeValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      active: "/home",
    };
  },
  computed: {
    languaga() {
      let str = "";
      if (this.$i18n.locale === "zh") {
        str = "EN";
      } else if (this.$i18n.locale === "en") {
        str = "中";
      }
      return str;
    },
  },
  watch: {
    activeValue(newValue, oldValue) {
      this.active = newValue;
      localStorage.setItem("active", newValue);
    },
  },
  mounted() {
    // console.log('i18n',this.$i18n.locale)
    if (localStorage.getItem("active")) {
      this.active = localStorage.getItem("active");
    }
  },
  methods: {
    handleSelect(path) {
      // console.log('path',path,pathList)
      this.active = path;
      localStorage.setItem("active", path);
      this.$router.push(path);
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      // 第一个参数是名字，第二个参数是当前的语言，第三个参数是表示 cookie 过期时间，可能是1（天）
      Cookie.set("language", lang, 1);
    },
  },
  destroyed() {
    localStorage.removeItem("active");
  },
};
</script>

<style lang="scss" scoped>
.head_box {
  border-bottom: 1px solid #e6e6e6;
  height: 60px;
  line-height: 60px;

  .head_img {
    width: 100%;
    height: 90%;
    background-image: url("@/assets/bangding.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
  }

  .el-menu-demo {
    font-weight: 600;
  }
  .head_lang {
    padding: 6px;
    background: #d2fbd9;
    color: #108e26;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
