import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon'

// 注册全局组件
Vue.component('svg-icon',SvgIcon)

// 定义加载svg目录函数
const req = require.context('./svg',true,/\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext);
// 加载目录下所有 svg文件
requireAll(req)