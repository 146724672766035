export default {
  navbar: {
    home: 'Home',
    product: 'Products',
    case: 'Case',
    about: 'About',
    contact: 'Contact',
    service_ability: 'Service&ability',
    join_us: 'Join Us',
  },
  home: {
    head_title: "Industrial Cognition AI Enabling Sustainability",
    head_slogan: '保护环境，人人有责。让地球远离污染，让绿色走进家园。一花一草皆生命，一枝一叶总关情。爱护小草，从你做起(en)',
    request: 'Get the solution for free',
    ai_title: 'Industrial AI Pioneer',
    ai_content: 'The technology is autonomous and controllable, with high prediction accuracy and interpretable results.International energy deployment experience + MODELICA, an industrial modeling and simulation language, to customize solutions for industrial scenarios incorporating AI technology to enable reliable decision-making, controllable risk, interpretable physical meaning of models, and eliminating the technical difficulties of sample diversity and coupling',
    expert_driven: 'Expert knowledge driven',
    expert_content: 'Guided by authoritative experts in the industrial field, AI learning models are more reliable and safer',
    break_trough: 'Breaking away from experience',
    break_content: "Intelligent decision optimization solutions to compensate for the limitations of industry experts' experience",
    visual: 'Visualization is productivity',
    visual_content: 'Industrial Mechanical Modeling, AI, Digital Twins Converge Visually and More Actionably',
    exercise: 'Self-study exercises',
    exercise_content: 'Industrial Scenario Mechanism Model Online Learning, Iterative Optimization',
    tabs_factory: 'Manufacturing Plant',
    oil_field: 'Intelligent Oilfield',
    real_estate: 'commercial real estate',
    partner: 'cooperative partner',
    welcome: 'Welcome new partners to work with us',
  },
  product: {
    head_title: 'Intelligent Factory Solutions',
    head_content: 'Pan-intelligence Technology offers a comprehensive suite of solutions for industrial customers in the field of intelligent factories. These solutions include energy management, energy-saving optimization and control, equipment predictive maintenance, and fault diagnosis. The company combines the mechanism model with cognitive AI to help enterprises digitally and intelligently transform into green and build zero-carbon factories ',
    head_deploy: 'By deploying a cold station control system, the combined annual energy efficiency of the refrigeration plant room can reach the good operating range (EER>5.0), achieving a highly efficient refrigeration plant room system.',
    energy_efficiency: 'Energy saving and fee reduction',
    energy_content: 'Energy savings increased by 20%-30%, and investment and operation and maintenance costs were reduced by more than 50%.',
    low_carbon: 'Low carbon operation',
    low_content: 'A medium-sized pharmaceutical factory, for example, can reduce carbon emissions by 1,000 tons in 1 year.',
    increase_production: 'Enhance production',
    increase_content: 'Factory comprehensive output increased by 10%-20%, and energy consumption reduced by 15%-25%',
    uation_room: 'Digital Intelligence Operation and Maintenance (DIM)',
    uation_content: 'Real-time monitoring of equipment failures can significantly reduce the failure rate of equipment, avoid redundant workload.',
    why_energy: 'Why industrial energy efficiency?',
    why_energy_info: 'Industrial energy efficiency is significant for China to achieve its goal of peaking carbon emissions by 2030, contributing more than 70%.',
    why_key: 'The key to industrial energy efficiency?',
    why_key_info: 'Air conditioning and compressed air systems account for 30% to 50% of energy consumption, and the interaction between air conditioning systems affects real-time optimization',
    why_realize: 'How to effectively implement？',
    why_realize_info: 'Pan-intelligence Technology’s 5.0 cognitive AI energy-saving solution effectively improves energy efficiency by 45%',
    one_energy: 'One-step energy savings of 45%',
    level_one: 'High cost, high risk, difficult to validate, affects production',
    level_two: 'Relying on experts cannot optimize in real time.',
    level_three: 'Black box model with large errors',
    level_four: 'Domestic mainstream algorithms',
    device_cloud: 'Device Cloud, Comprehensive IoT',
    device_cloud_info: 'Real-time data acquisition Real-time analysis Real-time monitoring A system to complete fault diagnosis, energy-saving optimization of two major goals',
    fan: 'ventilator',
    pump: 'pump',
    motor: '电机(en)',
    airComp: 'air compressor',
    dryer: 'cold dryer',
    cool: 'cooling tower',
    vrv: 'Central air-conditioning equipment',
    recycler: 'Waste heat recovery machine',
    system_highlights: '系统亮点',
    real_time_monitoring: '实时监控',
    simulation: '模拟仿真',
    fault_tracking: '故障追踪',
    data_visualization: '数据可视化',
  },
  case: {
    AI_empowerment: 'Industrial Cognition - AI Enablement',
    sustainable_development: 'Sustainable development',
    factory: 'manufacturing plant',
    project_context: 'Project Context',
    preenable: 'Preenable',
    after_empowerment: 'After Empowerment',

    moke_biology: 'A world-renowned foreign-funded pharmaceutical company listed in the Fortune Global 500',
    moke_project_info: 'The pharmaceutical factory is located in the domestic economic and technological development zone located in Nantong, and the first phase of the project covers a total floor area of 16,038.62 square meters and a total construction area of 24,248.99 square meters. The operation of space system has the characteristics of large flow and small temperature difference, and the operation content efficiency needs to be improved',
    moke_preenable: 'System instruments need to be updated to high-precision instruments, water pump, cooling tower frequency conversion control cabinet perfect; On the premise of meeting the end demand, it is urgent to optimize and upgrade the air conditioning automatic control system of refrigeration station. Intelligent management, deployment of optimized cold station BDM control software.',
    description1: 'Assembly of high-precision instrumentation , perfect water pump, Cooling tower frequency control cabinet',
    description2: 'Deployment of optimized cold station BDM optimization control software',
    moke_after_text1_title: 'Energy Station ',
    moke_after_text1_cont: 'Energy saving increased by 20%~25%',
    moke_after_text2_title: 'Meet the GMP management of pharmaceutical factory，',
    moke_after_text2_cont: 'Operation and maintenance efficiency increased by about 2 times;',
    moke_after_text3_cont: 'Operation and maintenance costs are reduced by about 50%',
    moke_after_text4_cont: 'Effectively prevent potential production risks in energy stations such as pharmaceutical plants.',

    commercial_real_estate: 'commercial real estate',
    intelligent_island: 'Zhangjiang Artificial Intelligence Island',
    land_project_info: 'Zhangjiang Artificial Intelligence Island covers a total area of 100,000 square meters, aiming to become a new landmark of AI in China, and strive to build the most influential and representative artificial intelligence industry gathering area and the highest level of application demonstration area reflecting the latest technology of artificial intelligence by the end of 2020. Bangding Intelligent Application was invited to participate in the intelligent construction scheme of indoor environment in the park.',
    land_preenable: 'The park has put forward higher operational requirements for the management and operation team, and needs to be built with the theme of safety, comfort, efficiency, convenience, and intelligence; In actual operation, it is difficult to centrally manage multiple buildings, systems, and equipment, and there is no dedicated facility management system configured; Employee salaries have been increasing year by year, and labor costs are constantly rising. It is difficult to reduce the energy consumption costs of colleagues, and overall costs are decreasing instead of increasing.',
    land_after_text1_title: 'Improve operation and maintenance management efficiency：',
    land_after_text1_cont: 'Construction equipment management automation, paperless, mobile, remote fault maintenance, diagnosis, online programming;',
    land_after_text2_title: 'Intelligent monitoring of equipment anomalies：',
    land_after_text2_cont: 'Device status monitoring alarms in real time, tracing fault information analysis and intelligent push to the corresponding personnel for maintenance；',
    land_after_text3_title: 'AI optimization to reduce consumption：',
    land_after_text3_cont: 'AI algorithm is used to deeply mine equipment data and analyze the best operation strategy of equipment to achieve energy saving and consumption reduction at the equipment management level；',
    land_after_text4_title: 'Automatic custody operation：',
    land_after_text4_cont: 'In combination with the energy supply data, the data model is automatically trained to complete the intelligent hosting of the entire system.',

    chengdu: 'Chengdu Mixheng City',
    chengdu_project_info: 'Chengdu Vientiane City is a large commercial complex under China Resources Group, with a total construction area of 188,000 square meters. The project transformation project needs to retain the original sensors and BA group control, and add some hardware, cloud platform, AI algorithm, etc.',
    chengdu_preenable: 'The use of fixed frequency pump tower can not effectively achieve the overall intelligent regulation according to different weather conditions and cold needs in time; Sensor problems occur frequently and data misses false positives, so comprehensive diagnosis and control is urgently needed.',
    chengdu_after_text1_cont1: 'central air-conditioning',
    chengdu_after_text1_cont2: 'Energy saving 15%-25%,',
    chengdu_after_text1_cont3: 'Realize intelligent operation and maintenance；',
    chengdu_after_text2_cont: 'Greatly reduce the labor intensity of front-line personnel and improve work efficiency; Enhance customer shopping comfort and effectively realize brand value',

    chart_ai_cont: 'To achieve sustainable development of industrial cognitive AI',
    chart_promise_head: 'Commitment to customers',
    chart_promise_info1: '24×7 online technical support, 48 hours warranty',
    chart_promise_info2: 'Continuous updates throughout the warranty period Software provides optional new features and system upgrades',
    chart_accretion: 'Add value to the plant',
    chart_energy_rate: 'Energy saving rate: Expected energy saving 20%-30%',
    chart_payback_time: 'Payback period: 1-2 years',
    chart_sustainability: 'Sustainability: Reducing carbon emissions',
    chart_running_attention: 'Operation and maintenance: Improve operation efficiency',
    chart_now_relation: 'Contact us now>',
  },
  about: {
    who_are_we: 'Who are we？',
    head_info: 'Bangding Wisdom is an industrial AI enterprise with international successful experience and independent controllable technology. The company integrates mature solutions and technical reserves in the field of industrial intelligence at home and abroad, actively responds to the call of the state, and is committed to providing core industrial AI engines for industrial customers, and working with customers to create the application ecology of domestic industrial AI+, realize localization and replace, and optimize the whole process of industrial production.',
    head_cont: 'The company provides in-depth customized industrial big data analysis and AI calculation, mechanism modeling simulation optimization and other artificial intelligence solutions and platforms for customers to manage energy efficiency, optimize production, energy conservation and emission reduction, and improve comprehensive income.',
    mission: 'Mission',
    mission_cont: 'Create domestic industrial AI+ application ecological optimization production process',
    energize: 'Energize',
    energize_cont: 'Industrial cognitive AI empowers sustainable development',
    vision: 'Vision',
    vision_cont: 'To realize Anbang Dingguo entrepreneurship with smart technology',
    we_story: 'The Story of Us',
    we_month_01: "September 2022",
    we_info_01: "Cooperate with Germany Merck, a top 500 pharmaceutical industry, to realize the intelligent upgrading of refrigeration room.",
    we_month_02: "May 2022",
    we_info_02: 'Won the Best Innovation Project award of Merck Asia Pacific Supplier Innovation Competition, and was invited to settle in Merck Shanghai Innovation base, becoming the first batch of enterprises to settle in.',
    we_month_03: "July 2021",
    we_info_03: 'Cooperate with Zhejiang University to empower Zhejiang University Central Control, a leading enterprise in the field of industrial control, and successfully complete related projects.',
    we_month_04: "April 2021",
    we_info_04: 'Obtained the access card of two major oil giants Sinopec and petrochina, successfully entered the oil industry chain and started relevant information and intelligent innovation projects.',
    we_month_05: "March 2021",
    we_info_05: 'Cooperate with China Resources Digital Technology to empower large commercial complexes and realize energy-saving renovation of buildings.',
    we_month_06: "The year 2020",
    we_info_06: "Cooperated with Zhangjiang Innovation Base to complete Zhangjiang Artificial Intelligence Island, which has been reported and appreciated by relevant media for many times.",
    we_month_07: "The year 2019",
    we_info_07: 'Shanghai Bangding Intelligent Technology Co., Ltd. was established.',

    honor: 'Honor',
    honor_text01: '2023 Won the Shanghai Pudong New Area Pearl Plan elite talent project support',
    honor_text02: "2023 Shanghai Pudong New Area Pearl Plan Elite talent project award",
    honor_text03: 'In 2023, the fourth Central Enterprise Yixing Innovation and Creativity Competition excellent results',
    honor_text04: '2023 36 KR "See New power" interview with the founder of Bangding wisdom Cao Jun: Autonomous and controllable cognitive AI, so that oil mining grow a "smart brain"',
    honor_text05: '2023 Ariba Battle of the Gods Intelligent Manufacturing track trophy',
    honor_text06: '2023 Alibaba Clash of the Gods Global Challenge Innovative Application Award',
    honor_text07: '2023 Alibaba Battle of the Gods Global Challenge Best Popularity Award',
    honor_text08: 'In 2022, China Resources senior executives visited the Bangding Smart project Human Intelligent Island',
    honor_text09: 'In 2022, he was invited to attend the technical lecture on AI and energy conservation',
    honor_text10: 'Best Innovation Project Award in the 2022 Merck Asia Pacific Supplier Innovation Competition',
    honor_text11: 'The first batch of enterprises to settle in Merck Shanghai Innovation Base in 2022',
    honor_text12: 'In 2022, Zhangjiang and Merck Innovation Bunding enable low carbon and energy saving',
    honor_text13: 'Dr. Biao Zou, Director of DuPont International Asia Pacific, visited Bonding Wisdom in 2021',
    honor_text14: 'In 2021, the first perspective reports the smart entrepreneurship process of Bunding',
    honor_text15: 'Exhibitors of the 22nd China Industry Fair in 2020',
    honor_text16: 'In 2020, the Central Propaganda Department visited and appreciated the achievements of Bangding AI big data algorithm for oil fields, industry and other fields',
    honor_text17: 'In 2020, the Global English Center of the Central Radio and Television Group "Pudong 30 years" series interview',
    honor_text18: 'In 2020, Xinzhuang Industrial Zone, "Keeping the Ambition of Returning to China" high-level innovation and entrepreneurship talent sharing meeting',
    honor_text19: 'In 2019, the Financial Program Center of the Central Radio and Television Group reported on "Entrepreneurial Heroes"',
    honor_text20: 'Winner of Smart Manufacturing Unit in 2018 Shanghai Youth Innovation and Entrepreneurship Competition',

    core_team: 'Core team',
    team_info: 'The team has rich industrial big data analysis and AI engineering technology practice, as well as international petroleum and petrochemical, medicine, aerospace, automatic control, construction and factory and other fields of excellent operation solutions experience, serving many domestic and international Fortune 500',
    people_name: 'Cao jun',
    education: 'Germany RWTH Aachen University Civil engineering and computers Doctor',
    post: 'Special Researcher, Green Building and New Energy Center, Tongji University (part-time)',
    people_info: 'Selected in the National Ministry of Human Resources and Social Security Chinese overseas students back to China Entrepreneurship start-up support Program, Shanghai Pujiang Talent, Shanghai Young Entrepreneurial talents, Minhang District overseas high-end talent introduction, Chunshen Pyramid outstanding talent 13 years of industrial AI and energy optimization technology research and development, project management and operation experience, Has served the United Nations International Energy Agency (IEA), the German Federal Ministry of Economic Affairs and Energy (BMWi) and other key projects research and development',
    forge_ahead: 'Forge ahead',

  },
  contact: {
    talent: {
      info: 'Leave specific information and we will provide you with a suitable solution. You can also contact us by phone or email.',
      serve: 'National service hotline',
      email: 'E-mail',
      address: 'Address',
      address_info: '4F, Block B, 800 Naxian Road, Pudong New Area, Shanghai',
      form_name: 'Please fill in your name',
      form_company: 'Please fill in the company name',
      form_countryOrArea: 'Please fill in country/region',
      form_phone: 'Please fill in a telephone number where you can be reached',
      form_email: 'Please fill in the email address where you can be reached',
      form_real_email: 'Please enter the correct email address',
      form_describe: 'Can you briefly describe what you are experiencing or what kind of support you need ',
      form_desc: 'Please enter a brief description of your requirements',
      form_submit: 'Submit',
    },
    cooperation: {
      head: 'Bangding wisdom creates the future',
      join: 'Look forward to your joining',
      deliver: 'Deliver mailbox',

      job_description: 'Position statement',
      qualification: 'Qualification requirements',

      post_title_01: 'Simulation modeling engineer',
      post_duty1_01: '1. Develop simulation models of energy and mechanical systems based on Modelica language, such as simulation models of production lines and energy conversion equipment;',
      post_duty1_02: '2. Participate in module design and development of Modelica modeling engine;',
      post_duty1_03: '3. Development and maintenance of simulation model base。',
      post_office1_01: "1. Bachelor degree or above, major in mechanical, energy and power, electrical engineering, etc. 0~3 years working experience。",
      post_office1_02: "2. Master the Modelica language, at least with Dymola/Mworks OpenModelice/Simulink of simulation software in the use of experience。",
      post_office1_03: "3. Bonus points: Experience in energy and construction related projects or simulation software use is preferred",
      post_office1_04: "4. Good communication skills and coordination skills。",

      post_title_02: "Hvac energy conservation engineer",
      post_duty2_01: "1. Participated in the research and development of HVAC energy saving optimization and operation and maintenance management software;",
      post_duty2_02: "2. Participate in the research and development of software algorithms for energy-saving optimization of HVAC system (participate and guide IT engineers to design algorithms);",
      post_duty2_03: "3. Participate in the development of software algorithms for fault diagnosis and prediction of HVAC system equipment (participate and guide IT engineers to design algorithms);",
      post_duty2_04: "4. Participate in the scheme design of building HVAC energy saving and optimization project;",
      post_office2_01: "1. Bachelor degree or above, major in building environment and energy application engineering (HVAC) or related;",
      post_office2_02: "2. 3-5 years or above HVAC design related work experience, HVAC energy saving project experience;",
      post_office2_03: "3. Familiar with HVAC or water supply and drainage construction process, technology and other professional knowledge;",
      post_office2_04: "4. Independent working experience in large-scale HVAC design and energy-saving renovation design projects is preferred;",
      post_office2_05: "5. Engineer qualification certificate is preferred;",
      post_office2_06: "Personality bonus：",
      post_office2_07: "Familiar with HVAC equipment simulation modeling technology is preferred (such as: EnergyPlus, Matlab, Simulink, etc.);",
      post_office2_08: "Programming development skills are preferred (e.g. Python or Java);",
      post_office2_09: "Good communication and coordination skills;",
      post_office2_10: "Skilled application of Office, AutoCAD and other software HVAC system drawings design。",

      post_title_03: " Algorithm Engineer",
      post_duty3_01: "1. Based on machine learning/deep learning and other technologies, combined with the expert knowledge of smart buildings, smart oil fields and other industrial fields, in construction operation and maintenance, oil mining and other industries, in-depth research and development of operational optimization algorithms and machine learning models that can be applied to customers;",
      post_duty3_02: "2. Develop automated prediction related systems for the above business scenarios, including data model construction, automated machine learning (feature engineering, model optimization, etc.), model deployment, including the actual development of modules and the docking of customers' own systems;",
      post_duty3_03: "3. Other related work arranged by the company。",
      post_office3_01: "1. Bachelor degree or above, major in statistics, computer science or related, at least 3 years experience in algorithm application;",
      post_office3_02: "2. Proficient in at least one of operations optimization, data mining, machine learning, deep learning, etc;",
      post_office3_03: "3. Proficient in SAS, R, python and other relevant statistical analysis languages, fast model iteration and code implementation ability, familiar with Python is preferred;",
      post_office3_04: "4. Good communication and coordination skills。",


      post_title_04: "Sales Manager (Energy Sector)",
      post_duty4_01: "1. Promote AI products and energy-saving technologies in foreign-owned pharmaceutical factories or large factories, responsible for market expansion and promotion, conduct industry demand analysis, and formulate market development strategies;",
      post_duty4_02: "2. Develop and maintain channels/partnerships, facilitate the acquisition of new projects;",
      post_duty4_03: "3. Provide tailored solutions for projects, including energy-saving calculations, accounting, economic analysis, and quotation proposals (the company will provide relevant training and support);",
      post_duty4_04: "4. Responsible for business docking of projects, coordinate project progress, lead business communication and negotiation, and ensure project contracts are signed and implemented;",
      post_duty4_05: "5. Responsible for maintaining relationships with major clients, and coordinate the collection of payments after project signing;",
      post_duty4_06: "6. Build and integrate the upstream and downstream ecological cooperation circle in the region;",
      post_duty4_07: "7. Conduct retrospectives on signed projects (business benefits and cost resource inputs, etc.), and propose optimization and improvement suggestions;",
      post_office4_01: "1. Bachelor's degree or above in HVAC, thermal energy, automation, mechanical engineering, or related engineering disciplines; proficiency in English communication is a plus;",
      post_office4_02: "2. Sales experience in the B2B customer industry;",
      post_office4_03: "3. Strong market analysis and judgment abilities, excellent negotiation skills, strong public relations and tracking service capabilities, ability to withstand pressure and work independently, preference given to those with high-quality customer resources;",
      post_office4_04: "4. Results-oriented, able to self-manage and self-motivate;",
      post_office4_05: "5. Hardworking spirit, willingness to endure hardships with the team, and ability to adapt to frequent business trips;",
      post_office4_06: "6. Good professional ethics and industry reputation (able to withstand independent background checks by third parties);",
    }
  },
  footer: {
    phoneTitle: 'Please call us for business cooperation',
    workTime: 'Working Time',
    service: 'For urgent needs, please contact customer service',
    address: 'Address',
    addressUrl: '4th Floor, Block B, No. 800, Naxian Road, Pudong New Area, Shanghai',
    phone: 'Phone',
    email: 'Email',
    copyright: 'Copyright © 2020 Shanghai Bonding Smart Technology Co., Ltd.',
    Wechat: 'Scan code to add customer service WeChat'
  }
}